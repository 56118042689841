<template>
	<div
		:class="{[$s.wrapper]:1, [$s.loading]:loading}"
		ref="wrapper"
	>
		<div :class="$s.title">Мои заказы</div>
		<div :class="$s.radio">
			<div
				v-for="el in filterList"
				:key="el.code"
				:class="{[$s.active]:filter===el.code}"
				@click="filter = el.code"
			>{{ el.name }}
			</div>
		</div>
		<div v-if="placeholder"></div>
		<div v-else-if="error">{{ error }}</div>
		<template v-else-if="orders.length > 0">
			<div :class="$s.table">
				<div :class="$s.head">
					<div
						v-for="el in sortList"
						:key="el.code"
						:class="{
                            [$s.col]: 1,
                            [$s.sort]:el.code===sort,
                            [$s.desc]:(el.code===sort) && sortDesc,
                        }"
						@click="toggleSort(el.code)"
					>
						{{ el.name }}
						<img
							v-if="el.code===sort"
							src="@/assets/img/Personal/sortTriangle.svg"
						>
					</div>

				</div>
				<div :class="$s.body">
					<OrderRow
						v-for="order in orders"
						:key="order.ID"
						:order="order"
					/>
				</div>
			</div>
			<Paginator
				v-model="ordersCurrentPage"
				:totalPages="totalPages"
				@changePage="setOrdersCurrentPage($event)"
			/>
		</template>
		<div
			v-else
			:class="$s.blank"
		>
			<div>{{ msg[0] }}</div>
			<div>{{ msg[1] }}</div>
			<router-link to="/menu">Выбрать меню</router-link>
		</div>
	</div>
</template>

<script>
import Paginator from '@/components/block/Personal/Paginator.vue'
import axios from 'axios';
import qs from 'qs';
import OrderRow from '@/components/block/Personal/Orders/OrderRow.vue'
import {mapState, mapMutations} from "vuex";

export default {
	components: {Paginator, OrderRow},
	data()
	{
		return {
			filter: 'all',
			filterList: [
				{name: 'Все', code: 'all'},
				{name: 'Активные', code: 'active'},
				{name: 'Завершенные', code: 'completed'},
			],

			sort: 'ID',
			sortDesc: true,
			sortList: [
				{name: '#', code: 'ID'},
				{name: 'Оплата', code: 'DATE_PAYED'},
				{name: 'Сумма', code: 'PRICE'},
				{name: 'Отгрузка', code: 'DELIVERY_DATE'},
				{name: 'Статус', code: 'STATUS_ID'},
			],

			totalPages: 1,

			orders: [],

			loading: false,
			placeholder: true,
			error: null,
			msg: []
		}
	},
	methods: {
		...mapMutations('personal', ['setOrdersCurrentPage']),
		toggleSort(code)
		{
			if (code === this.sort)
			{
				this.sortDesc = !this.sortDesc;
			} else
			{
				this.sort = code;
				this.sortDesc = true
			}
		},
		async load()
		{
			this.loading = true;
			let data = qs.stringify({
				page: this.ordersCurrentPage,
				sort: this.sort,
				sortDesc: this.sortDesc,
				filter: this.filter
			});

			let result = await axios.post('/personal/orders/', data);
			if (result.data.success)
			{
				this.orders = result.data.orders;
				this.totalPages = result.data.totalPages;
				this.msg = result.data.msg;
			} else
			{
				this.error = result.data.error;
				console.error('personal/orders', result);
			}
			this.loading = false;
			this.placeholder = false
		}

	},
	mounted()
	{
		this.load()
	},
	computed: {
		...mapState('personal', ['ordersCurrentPage']),
	},
	watch: {
		ordersCurrentPage()
		{
			this.load();
		},
		sort()
		{
			this.setOrdersCurrentPage(1);
			this.load();
		},
		sortDesc()
		{
			this.setOrdersCurrentPage(1);
			this.load();
		},
		filter()
		{
			this.setOrdersCurrentPage(1);
			this.load();
		},

	}

}
</script>

<style
	module="$s"
	lang="scss"
>
.wrapper
{
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 32px 22px;
	align-content: start;

	font-family: $mainFont;

	.title
	{
		font-weight: bold;
		font-size: 24px;
		line-height: 32px;
		margin-bottom: 24px;
	}

	&.loading
	{
		opacity: 0.5;
	}
}

.radio
{
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;

	font-family: $mainFontBold;
	font-size: 16px;
	line-height: 24px;

	background-color: white;
	padding-top: 5px;
	padding-bottom: 15px;

	@media (max-width: 875px)
	{
		font-size: 12px;
		grid-auto-columns: 1fr;
	}

	*
	{
		color: $textColor;
		background: $greyBackground;
		border: 1px 1px 1px 0 solid $greyBorder;
		padding: 12px 32px;
		@media (max-width: 875px)
		{
			text-align: center;
			padding: 6px 12px;
		}
		cursor: pointer;

		&:first-child
		{
			border-left: 1px;
			border-radius: 6px 0 0 6px;
		}

		&:last-child
		{
			border-radius: 0 6px 6px 0;
		}

		&.active
		{
			background: $green;
			color: white;
		}
	}
}


.table
{

	margin-bottom: 32px;


	@media (max-width: 875px)
	{
		overflow-y: auto;
		&::-webkit-scrollbar
		{
			width: 0;
			height: 0;
		}
	}


	.head
	{
		background-color: white;
		min-width: 500px;
		display: grid;
		grid-template-columns: minmax(70px, 0.7fr) 1.2fr 1fr 1.2fr 1.2fr 24px;
		padding: 0 24px;
		user-select: none;
		cursor: pointer;

		font-family: $mainFontMedium;
		color: $greyLightText;
		font-size: 14px;
		line-height: 24px;

		.sort
		{
			img
			{
				vertical-align: 2px;

			}

			&:not(.desc) img
			{
				transform: rotate(180deg);
			}
		}

		.col
		{
			padding: 0 5px;
		}
	}

	.body
	{
		display: grid;
		gap: 8px;
		min-width: 500px;
	}
}

.blank
{
	text-align: center;
	font-family: $mainFont;
	margin: 150px 0;

	div:nth-child(1)
	{
		color: $textColor;
		font-family: $mainFontBold;
		font-size: 32px;
		line-height: 40px;
		margin-bottom: 16px;
	}

	div:nth-child(2)
	{
		color: $greyLightText;
		font-size: 16px;
		line-height: 40px;
		margin-bottom: 24px;
	}

	a
	{
		color: white;
		background-color: $green;
		border-radius: 30px;
		border: none;
		line-height: 56px;

		padding: 0 65px;
		font-family: $mainFontBold;
		font-size: 16px;
		display: inline-block;
	}
}
</style>

<template>
	<div :class="$s.wrapper">
		<div
			:class="{[$s.row]:1, [$s.active]:show}"
			@click="show=!show"
		>
			<div :class="$s.col">#{{ order.ID }}</div>
			<div :class="$s.col">{{ order.DATE_PAYED }}</div>
			<div :class="$s.col">{{ parseFloat(order.PRICE).toLocaleString() }} руб.</div>
			<div :class="$s.col">{{ order.DELIVERY_DATE }}</div>
			<div :class="$s.col">{{ order.STATUS }}</div>

			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M9.9995 18.9998C9.7735 18.9998 9.5465 18.9238 9.3595 18.7678C8.9355 18.4148 8.8785 17.7838 9.2315 17.3598L13.7075 11.9888L9.3925 6.62683C9.0465 6.19683 9.1145 5.56683 9.5445 5.22083C9.9755 4.87483 10.6045 4.94283 10.9515 5.37283L15.7795 11.3728C16.0775 11.7438 16.0735 12.2738 15.7685 12.6398L10.7685 18.6398C10.5705 18.8768 10.2865 18.9998 9.9995 18.9998Z"
					fill="#40AE49"
				/>
			</svg>


		</div>
		<div :class="{[$s.loading]:loading}"></div>
		<div
			v-if="placeholder"
			:class="$s.placeholder"
		></div>
		<div v-else-if="error">{{ error }}</div>
		<div
			:class="$s.detail"
			v-if="show && detail.length"
		>
			<div :class="$s.order_details">
				<div
					v-for="el in detail"
					:key="el.title"
				>
					<div :class="$s.title">{{ el.title }}</div>
					<div
						:class="$s.value"
						v-html="el.value"
					></div>
				</div>
				<div
					:class="$s.delivery_price"
					v-if="deliveryPrice"
				>
					<div :class="$s.title ">Стоимость доставки</div>
					<div
						:class="$s.value"
						v-html="deliveryPrice"
					></div>
				</div>
			</div>
			<div :class="$s.order_products">
				<div :class="$s.head">
					<div>Товар</div>
					<div>Кол-во</div>
					<div>Сумма</div>
				</div>

				<div v-for="product in products">
					<div
						v-if="product.isHeader === true || product.isPerson"
						:class="$s.separator"
					>{{ product.name }}
					</div>
					<div
						v-else
						:class="{[$s.row]:1, [$s.sub_product]: !product.price}"
					>
						<div :class="$s.id">{{ product.number }}</div>
						<img
							:class="$s.img"
							:src="product.img"
						/>
						<div :class="$s.name">{{ product.name }}</div>
						<div
							:class="$s.weight"
							v-if="product.weight"
						>{{ product.weight }} гр.
						</div>
						<div :class="$s.count">{{ product.count }} шт</div>
						<div
							:class="$s.sum"
							v-if="product.price"
						>{{ parseFloat(product.price * product.count).toLocaleString() }} руб
						</div>
					</div>
				</div>
			</div>
			<div :class="$s.footer">
				<a :class="$s.feedback"></a>
				<button
					v-if="repeatPayUrl"
					@click="handleRepeatPayClick"
					:class="{[$s.repeat]:1, [$s.disabled]:disablePayBtn}"
					:disabled="disablePayBtn"
				>Повторить оплату
				</button>
				<div :class="$s.sum">
					<b>Итого</b>
					{{ parseFloat(totalSum).toLocaleString() }} руб
				</div>
			</div>
			<div
				v-if="repeatPayError"
				:class="$s.error"
			>{{ repeatPayError }}
			</div>
		</div>

	</div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';

export default {
	props: ['order'],
	data()
	{
		return {
			show: false,

			detail: [],
			products: [],
			totalSum: null,
			deliveryPrice: null,
			repeatPayUrl: null,

			placeholder: true,
			loading: false,
			disablePayBtn: false,
			repeatPayError: null,
			error: null,
			showPaymentWidget: '',

			isMobile: !!process.env.VUE_APP_MOBILE,
			baseUrl: process.env.BASE_URL,
		}
	},
	methods: {
		async load()
		{
			this.loading = true;
			const data = await this.$store.dispatch('order/fetchOrderDetails', this.order.ID);

			if (data)
			{
				this.detail = data.order;
				this.products = data.products;
				this.deliveryPrice = data.deliveryPrice;
				this.totalSum = data.totalSum;
				this.repeatPayUrl = data.repeatPayUrl
				this.showPaymentWidget = data.show_payment_widget
			}

			this.loading = false;
			this.placeholder = false;
		},
		async handleRepeatPayClick()
		{
			this.disablePayBtn = true;

			let result = await axios.post('/personal/checkOrderPaid/', qs.stringify({orderId: this.order.ID}));

			// записываем айди в куки
			let topayData = this.$cookie.set("topay", this.order.ID);

			if (!result.data.success || result.data.paid)
			{
				this.disablePayBtn = false;
				this.repeatPayError = result.data.msg;
				return;
			}
			const userAgent = navigator.userAgent.toLowerCase();

			if (userAgent.indexOf('firefox') == -1 && this.showPaymentWidget === 'Y')
			{
				this.$router.push('/payment');
				return;
			} else
			{
				let a = document.createElement('a');
				a.href = this.repeatPayUrl;
				a.click();
			}
			this.disablePayBtn = false;
		}
	},
	watch: {
		show(val)
		{
			if (val) this.load()
		}
	}
}
</script>

<style
	module="$s"
	lang="scss"
>
.col
{
	padding: 0 5px;
	white-space: nowrap;
	font-size: 12px;
}

.wrapper
{
	border-radius: 8px;
	font-family: $mainFontSemi;
	color: $textColor;
	font-size: 14px;
	line-height: 24px;
	min-width: 0;


	& > .row
	{
		padding: 0 24px;
		background: $greyBackground;
		height: 56px;

		display: grid;
		grid-template-columns: minmax(70px, 0.7fr) 1.2fr 1fr 1.2fr 1.2fr 24px;
		align-items: center;

		cursor: pointer;
		white-space: pre-wrap;

		svg
		{
			transition: transform 0.3s;
		}

		&.active
		{
			background-color: $lightGreenBackground;
			color: $green;

			svg
			{
				transform: rotate(90deg);
			}
		}

	}

	.detail
	{
		padding: 24px;
	}
}

.delivery_price
{
	font-family: $mainFontBold;

	div
	{
		color: $green;
	}
}

.order_details
{
	line-height: 30px;
	margin-bottom: 32px;
	font-size: 14px;
	color: $textColor;
	font-family: $mainFont;


	> *
	{
		border-bottom: 1px solid $greyBorder;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.title
	{
		font-weight: 600;

	}

	.value
	{
		font-weight: 500;
	}

}

.order_products
{
	margin-bottom: 16px;

	.head
	{
		display: grid;
		grid-template-columns: 1fr 25% 25%;
	}

	.row
	{
		display: grid;
		grid:
                "id img name count sum"
                "id img weight count sum"
                / 40px 54px 1fr 25% 25%;
		color: $textColor;
		border-bottom: 1px solid $greyBorder;
		padding: 6px 0;
		gap: 0 12px;
		align-items: center;

		& > .id
		{
			grid-area: id;
			color: $greyLightText
		}

		& > .img
		{
			grid-area: img;
			width: 100%;
		}

		& > .name
		{
			grid-area: name;
		}

		& > .weight
		{
			grid-area: weight;
			color: $greyLightText
		}

		& > .count
		{
			grid-area: count;
		}

		& > .sum
		{
			grid-area: sum;
		}

		&.sub_product
		{
			font-family: $mainFont;

			& > .id
			{
				grid-area: img;
			}
		}

	}

	.separator
	{
		color: $greyLightText;
		padding-left: 117px;
		border-bottom: 1px solid $greyBorder;
	}
}

.footer
{
	display: grid;
	grid: "feedback repeat sum" / 1fr max-content 25%;

	line-height: 40px;

	.link
	{
		color: #56B65E;
		grid-area: feedback;
	}

	.repeat
	{
		grid-area: repeat;
		color: white;
		background: #56B65E;
		border: 1px solid #56B65E;
		box-sizing: border-box;
		border-radius: 6px;

		padding: 0 16px;
		margin-right: 34px;

		&.disabled
		{
			background: #b6b7b8;
			border: 1px solid #b6b7b8;
			cursor: default;
		}
	}

	.sum
	{
		grid-area: sum;

	}
}

.error
{
	color: #FD470E;
	line-height: 20px;
	margin-top: 10px;
}

.placeholder
{
	/*height: 300px;*/
}

.loading
{
	height: 3px;
	margin-top: -3px;

	@keyframes animate-stripes
	{
		0%
		{
			background-position: 0 0;
		}
		100%
		{
			background-position: 100vw 0;
		}
	}

	background-image: linear-gradient(
			90deg,
			#40AE49 50%,
			transparent 50%
	);
	animation: animate-stripes 5s linear infinite;
}
</style>
